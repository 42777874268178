//================================================================
//  Component: BreadCrumb component for the Browse Page
//================================================================

//  Purpose: Creates clickable Breadcrumbs and route the user as required

//  Properties: N/A

//  BreadCrumb:
//    <BreadCrumb></BreadCrumb>    

//================================================================


//Libraries
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

//Contexts

//Components

//Functions

//Images

//CSS


export default function BreadCrumb() {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const params = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    const [folderPath, setFolderPath] = useState(['/']);

    //------------------------------------------------------
    //  functions
    //------------------------------------------------------

    // Take the user back to a previous folder, based off there selection
    function handleClick(currentIndex){

        // Find all the previous folders, EXCLUDING the 'datasetid'
        // ['folder 1', 'folder 2', etc...]
        const previousFolders = folderPath?.filter((folder, index) => index <= currentIndex && folder !== params.id);

        // Create a new searchParam string, '/folder 1/folder 2/etc...'
        let newParams;
        previousFolders.forEach((value, index) => {

            if (index === 0) return newParams = value;

            newParams = `${newParams}/${value}`;

        });

        // Trigger the navigation
        navigate(`/browse/${params.id}?path=/${encodeURIComponent(newParams)}/`);

    }

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // onLoad
    //  1. Create a breadcrumb path, based off the users searchParms ['dataset', 'folder 1', 'folder 2', etc...]
    useEffect(() => {

        const dataSetId = params.id;

        if (dataSetId === '') return;
        if (dataSetId === null) return;

        const results = [];
        searchParams.get('path')?.split('/')?.forEach((folder, index) => {

            if (index === 0) {

                return results.push(dataSetId);

            }
            if (folder.length > 0) {

                return results.push(folder);
                
            }
        });

        setFolderPath(results);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <h6 className='font-normal'>

            {/* Returns the user to the home page */}
            <span className='cursor-pointer pr-1' onClick={() => navigate('/')}>
                Datasets
            </span>
            <span className='pr-1 cursor-default'>{'>'}</span>

            {
                folderPath?.map((folder, index) => (

                    // Datasetid (always the root of the folder path)
                    index === 0 ? 
                        
                        <label key={index} className='cursor-pointer' onClick={() => navigate(`/browse/${params.id}?path=/`)}>
                            {folder}
                            <span className={`px-1 cursor-default ${index + 1 === folderPath.length && 'hidden'}`}>{'>'}</span>
                        </label>

                    // All other folders
                    :
                        <>
                            <label key={index} className={` ${index + 1 === folderPath.length ? 'font-semibold cursor-default' : 'cursor-pointer'}`} onClick={() => handleClick(index)}>
                                {folder}
                            </label>
                            <span key={`${index}-1`} className={`px-1 cursor-default ${index + 1 === folderPath.length && 'hidden'}`}>{'>'}</span>
                        </>

                ))
            }
        </h6>
    )
}
